import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import Tag from "../components/tag"

export default function Tags({data}) {
    return (
        <Layout>
            <div>
                <h2>Tags</h2>
                <ul class="tags">
                    {data.allMarkdownRemark.group.map(tag => (
                        <Tag tag={tag.fieldValue} />
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
        limit: 2000
        sort: {fields: frontmatter___tags, order: DESC}
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`